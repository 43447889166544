















































































































































import { Component, Vue } from 'vue-property-decorator';
import { AuthError } from 'msal';
import { Container } from 'inversify';
import ServiceDetails, { Service, Services, ServiceUpdate } from '../../interfaces/api/Service';
import container from '../../injection/Container';
import { AuthService } from '../../msal/AuthService';

@Component
export default class ServicesEdit extends Vue {
  dataLoading = false;

  dialog = false;

  deleteDialog = false;

  formValid = false;

  serviceDetails: Array<Service> = new Array<ServiceDetails>();

  busAuthService: AuthService;

  private apiUrl = process.env.NODE_ENV === 'production' ? 'https://api.sprkl.app' : process.env.VUE_APP_API_URL;

  private diContainer: Container = container;

  services: Service[] = new Array<Service>();

  serviceId?: string;

  serviceName = '';

  serviceDescription = '';

  servicePrice = 0;

  serviceToDelete?: Service;

  // Snackbar
  snackbar = false;

  snackbarContent = '';

  snackbarColor = '';

  constructor() {
    super();
    this.busAuthService = this.diContainer.getNamed<AuthService>('AuthService', 'business');
  }

  async mounted() {
    await this.mountEdit();
  }

  async mountEdit() {
    this.dataLoading = true;

    if (this.$store.state.accessToken === undefined) {
      // alert('No Token');
      try {
        const response = await this.busAuthService.login();
        this.$store.commit('authSuccess', this.busAuthService.getAccount());
        this.$store.commit('accessToken', response.idToken.rawIdToken);

        // console.log(`AccessToken ${JSON.stringify(response.idToken.rawIdToken)}`);
      } catch (e) {
        if (e instanceof AuthError) {
          //   console.log(e);
        } else {
          throw e;
        }
      }
      this.mountEdit();
      return;
    }

    fetch(`${this.apiUrl}/api/businesses/services`, {
      method: 'get',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.$store.state.accessToken}`
      })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Response Status: ${response.status}\nMessage: ${response.statusText}`);
        }
        return response.json() as Promise<Services>;
      })
      .then((data: Services) => {
        // this.convertServices(data);
        this.services = data.services;

        this.dataLoading = false;
      })
      .catch(err => {
        this.dataLoading = false;
        this.snackbar = true;
        this.snackbarContent = 'There was an error loading the data please try again.';
        this.snackbarColor = 'error';
      });
  }

  saveService(event: Event) {
    if (event) {
      event.preventDefault();
    }

    if (!this.formValid) {
      this.snackbar = true;
      this.snackbarContent = 'The input is invalid!';
      this.snackbarColor = 'error';
      return;
    }

    this.dataLoading = true;

    const data: Service = {
      id: this.serviceId,
      name: this.serviceName,
      description: this.serviceDescription,
      price: this.servicePrice
    };

    const dataArray = new Array<Service>();
    dataArray.push(data);

    // submit form
    fetch(`${this.apiUrl}/api/businesses/services`, {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.$store.state.accessToken}`
      }),
      body: JSON.stringify(dataArray)
    })
      .then(response => {
        if (!response.ok) {
          if (response.status === 400) {
            this.displaySnackBar('There was an error saving your data please try again!', 'error');
            throw new Error(`Response Status: ${response.status}\nMessage: ${response.statusText}`);
          }
        }

        this.displaySnackBar('Your updates have been saved!', 'success');

        return response.json() as Promise<ServiceUpdate>;
      })
      .then(async () => {
        // this.services.push(newService.succeeded[0]);
        this.services = new Array<Service>();

        await this.mountEdit();

        this.serviceName = '';
        this.serviceDescription = '';
        this.servicePrice = -1;
        this.formValid = true;

        this.dataLoading = false;
        this.dialog = false;
      })
      .catch(() => {
        this.displaySnackBar('There was an error saving your data please try again!', 'error');
        this.dataLoading = false;
      });
  }

  deleteService(eventService: Service, event: Event) {
    if (event) {
      event.preventDefault();
    }

    this.serviceToDelete = eventService;

    this.deleteDialog = true;
  }

  deleteServiceConfirmed(event: Event) {
    if (event) {
      event.preventDefault();
    }

    this.dataLoading = true;

    if (this.serviceToDelete === undefined) {
      this.displaySnackBar('No item can be identified to delete!', 'error');
      return;
    }

    const data: Service = {
      id: this.serviceToDelete.id,
      name: this.serviceToDelete.name,
      description: this.serviceToDelete.description,
      price: this.serviceToDelete.price
    };

    const dataArray = new Array<Service>();
    dataArray.push(data);

    // submit form
    fetch(`${this.apiUrl}/api/businesses/services`, {
      method: 'delete',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.$store.state.accessToken}`
      }),
      body: JSON.stringify(dataArray)
    })
      .then(async response => {
        if (response.status < 200 || response.status > 299) {
          //   if (response.status === 400) {
          this.displaySnackBar('There was an error saving your data please try again!', 'error');
          throw new Error(`Response Status: ${response.status}\nMessage: ${response.statusText}`);
          //   }
        }

        this.displaySnackBar('Your updates have been saved!', 'success');

        await this.mountEdit();

        this.serviceToDelete = undefined;
      })
      .catch(() => {
        this.displaySnackBar('There was an error saving your data please try again!', 'error');
      });

    this.dataLoading = false;
    this.deleteDialog = false;
  }

  editService(eventService: Service, event: Event) {
    if (event) {
      event.preventDefault();
    }

    this.serviceId = eventService.id;
    this.serviceName = eventService.name;
    this.serviceDescription = eventService.description;
    this.servicePrice = eventService.price;
    this.dialog = true;
  }

  displaySnackBar(content: string, color: string) {
    this.snackbar = true;
    this.snackbarContent = content;
    this.snackbarColor = color;
  }

  serviceRules = {
    name: [(val: string) => (val || '').length > 0 || 'This field is required'],
    description: [(val: string) => (val || '').length > 0 || 'This field is required'],
    price: [(val: number) => val > 0 || 'This field is required']
  };
}
